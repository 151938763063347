import React, { useCallback } from 'react';
import './Team.css';

const images = [
  { id: 1, src: '/onunu11.png', title: 'Join The Team', link: '/join-team' },
  { id: 2, src: '/onunu12.png', title: 'Be A Vendor', link: '/be-a-vendor' },
  { id: 3, src: '/onunu8.jpg', title: 'Be A Sponsor', link: '/be-a-sponsor' },
];

const Team = () => {
  // Memoize the click handler using useCallback
  const handleImageClick = useCallback((image) => {
    alert(`You clicked on: ${image.title}`);
  }, []);

  return (
    <div className="team-wrapper">
      <div className="team-container">
        {images.map((image) => (
          <div key={image.id} className="team-item">
            <img
              src={image.src}
              alt={image.title}
              className="team-image"
              onClick={() => handleImageClick(image)}
            />
            <a href={image.link} className="image-button-link">
              <button className="image-button">
                {image.title}
              </button>
            </a>
          </div>
        ))}
      </div>
      <div className="info-text">
        <p>
          Onunu Festival is a product of strong desire and zeal to spread the culture, tradition, and most importantly, one of the foods that showcases the Rivers culture.
        </p>
      </div>
    </div>
  );
};

export default Team;
