import React from 'react';
import './Disclaimer.css';

const GlassText = () => {
  return (
    <div className="glass-container">
      <p className="glass-text">
        Onunu Festival is Keen on the promoting of culture and food<br />
        of the Rivers people.<br />
        To sustain this vision for the future, we have made plans<br />
        To embark on the following events in subsequent Time .<br />
        Onunu Festival: University Tour.<br />
        Onunu Native Party: Valentine and Easter.<br />
        Onunu festival: Lagos and London Editions.<br />
      </p>
    </div>
  );
};

export default GlassText;
