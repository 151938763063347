import React from 'react';
import './Footer.css'; // Import the external CSS file
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Logo Space */}
        <div className="footer-section logo-section">
          <img src="/logo.png" alt="Logo" className="logo" />
        </div>
        
        {/* Quick Links */}
        <div className="footer-section quick-links">
          <h3>Quick Links</h3>
          <a href="/">Home</a>
          <a href="/about">About</a>
          <a href="/contact">Contact</a>
        </div>
        
        {/* Social Media Links */}
        <div className="footer-section social-links">
          <a href="https://www.facebook.com/share/Bq2tQiNTPQzFrtAS/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a href="https://www.instagram.com/p/DADr1OLNS8Z/?igsh=MW45b3lwZ3c1NThyZw==" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
        </div>
        
        {/* Contact Information */}
        <div className="footer-section contact-number">
          <p>Contact: 07034617936, +234 703 692 8121</p>
          <p>Email: theonunufestival@gmail.com</p>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="footer-bottom">
        <p>©Copyright 2024 OnunuFestival. All rights Reserved, Developed by Timzdigital</p>
      </div>
    </footer>
  );
};

export default Footer;
