import React, { useState, useEffect } from 'react';
import './Home.css';

const Home = () => {
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const eventDate = new Date('2024-12-08T00:00:00'); // Event date set to December 7, 2024

    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = eventDate - now;

      if (difference <= 0) {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });
    };

    calculateTimeLeft();
    const intervalId = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="homepage">
      <div className="overlay">
        <div className="text-container">
          <h1 className="festival-title">
            <span className="onunu">Onunu</span> <span className="festival">Festival 2024</span>
            
          </h1>
          <span className="onunu-text">HEROES</span>
          <p className="love-jesus">CELEBRATING IMPACT, SETTING THE PACE 8TH DECEMBER 2024 </p>
          <div className="countdown">
            <div className="countdown-item">
              <span className="countdown-number">{timeLeft.days}</span>
              <span className="countdown-label">Days</span>
            </div>
            <div className="countdown-item">
              <span className="countdown-number">{timeLeft.hours}</span>
              <span className="countdown-label">Hours</span>
            </div>
            <div className="countdown-item">
              <span className="countdown-number">{timeLeft.minutes}</span>
              <span className="countdown-label">Minutes</span>
            </div>
            <div className="countdown-item">
              <span className="countdown-number">{timeLeft.seconds}</span>
              <span className="countdown-label">Seconds</span>
            </div> 
          </div>
        </div>  

        <div className="button-container">
        <a href="https://wa.link/4ha8e0" className="btn-link">
  <button className="btn become-vendors">Become a Vendor</button>
</a>

<a href="https://tix.africa/discover/onunufesti/checkout?step=tickets" className="btn-link">
  <button className="btn buy-ticketss">Buy Tickets</button>
</a>

        </div>
      </div>
    </div>
  );
};

export default Home;
